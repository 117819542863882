<template>
  <section class="content">
    <div class="table-responsive">
    <table class="table table-hover" ref="tablecourier">
      <thead>
        <tr>
          <th>Nama</th>
          <th>Prefix</th>
          <th>Token</th>
          <th>Username</th>
          <th>Password</th>
          <th>URL</th>
          <th>Dev Token</th>
          <th>Dev Username</th>
          <th>Dev Password</th>
          <th>Dev URL</th>
          <th>Production</th>
          <th>Status</th>
          <th>Auto Access</th>
        </tr>
      </thead>
      <tbody @click="handleClick"></tbody>
    </table>
    </div>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form role="form" @submit="submitForm">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p v-if="errors.length" class="alert alert-danger alert-dismissible fade show" role="alert">
                        <b>Please correct the following error(s):</b>
                        <ul>
                            <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                        </ul>
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </p>
              <div class="row">
                <div class="col-md-6 form-group">
                  <label class="control-label">Nama</label>
                  <input
                    id="nama"
                    class="form-control"
                    v-model="form.nama"
                    type="text"
                    required="required"
                    name="nama"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Prefix</label>
                  <input
                    id="prefix"
                    class="form-control"
                    v-model="form.prefix"
                    type="text"
                    required="required"
                    name="prefix"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Token Key</label>
                  <input
                    id="token"
                    class="form-control"
                    v-model="form.token"
                    type="text"
                    name="token"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Username</label>
                  <input
                    id="username"
                    class="form-control"
                    v-model="form.username"
                    type="text"
                    name="username"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Password</label>
                  <input
                    id="password"
                    class="form-control"
                    v-model="form.password"
                    type="text"
                    name="password"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">URL</label>
                  <input
                    id="url"
                    class="form-control"
                    v-model="form.url"
                    type="text"
                    name="url"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Dev Token</label>
                  <input
                    id="dev_token"
                    class="form-control"
                    v-model="form.dev_token"
                    type="text"
                    name="dev_token"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Dev Username</label>
                  <input
                    id="dev_username"
                    class="form-control"
                    v-model="form.dev_username"
                    type="text"
                    name="dev_username"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Dev Password</label>
                  <input
                    id="dev_password"
                    class="form-control"
                    v-model="form.dev_password"
                    type="text"
                    name="dev_password"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Dev URL</label>
                  <input
                    id="dev_url"
                    class="form-control"
                    v-model="form.dev_url"
                    type="text"
                    name="dev_url"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label"> </label>
                  <input
                    id="production"
                    v-model="form.production"
                    type="checkbox"
                    name="production"
                  />
                  <label for="production">Production</label>
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label"> </label>
                  <input
                    id="active"
                    v-model="form.active"
                    type="checkbox"
                    name="active"
                  />
                  <label for="active">Active</label>
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label"> </label>
                  <input
                    id="auto_access"
                    v-model="form.auto_access"
                    type="checkbox"
                    name="auto_access"
                  />
                  <label for="auto_access">Auto Access</label>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">
                Save changes
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
  name: "Data Kurir",
  data() {
    return {
      errors: [],
      method: "",
      roles: "",
      formTitle: "Tambah Kurir",
      form: {
        active: [],
        nama: "",
        prefix: "",
        token: "",
        username: "",
        password: "",
        url: "",
        dev_token: "",
        dev_username: "",
        dev_password: "",
        dev_url: "",
        production: [],
        auto_access: [],
      },
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);
  },
  methods: {
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
    },
    submitForm: function (ev) {
      const e = this.$refs;
      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");
      var urlSubmit = "/master/courier";
      if (this.method == "PUT") urlSubmit = "/master/courier/" + this.form.id;

      authFetch(urlSubmit, {
        method: this.method,
        body: data,
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (!js.success) {
            console.log(js.details);

            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }

            return;
          }
          this.table.api().ajax.reload();
          $(e.formDialog).modal("hide");
        });

      ev.preventDefault();
    },
  },
  mounted() {
    const e = this.$refs;
    var self = this;
    this.table = createTable(e.tablecourier, {
      title: "Data Kurir",
      roles: this.$route.params.roles,
      processing: true,
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
      ajax: "/master/courier",
      columns: [
        { data: "nama" },
        { data: "prefix" },
        { data: "token" },
        { data: "username" },
        { data: "password" },
        { data: "url" },
        { data: "dev_token" },
        { data: "dev_username" },
        { data: "dev_password" },
        { data: "dev_url" },
        { data: "production" },
        { data: "active" },
        { data: "auto_access" },
      ],
      filterBy: [0, 1],
      rowCallback: function (row, data) {
        $("td:eq(3)", row).html(
          '<a class="link link-role" data-id="' + data.nama + '">' + "</a>"
        );

        if (data.token) {
          $("td:eq(2)", row).html(
            '<h5 class="text-center"><i class="fa fa-check"></i></h5>'
          );
        }

        if (data.username) {
          $("td:eq(3)", row).html(
            '<h5 class="text-center"><i class="fa fa-check"></i></h5>'
          );
        }

        if (data.password) {
          $("td:eq(4)", row).html(
            '<h5 class="text-center"><i class="fa fa-check"></i></h5>'
          );
        }

        if (data.url) {
          $("td:eq(5)", row).html(
            '<h5 class="text-center"><i class="fa fa-check"></i></h5>'
          );
        }

        if (data.dev_token) {
          $("td:eq(6)", row).html(
            '<h5 class="text-center"><i class="fa fa-check"></i></h5>'
          );
        }

        if (data.dev_username) {
          $("td:eq(7)", row).html(
            '<h5 class="text-center"><i class="fa fa-check"></i></h5>'
          );
        }

        if (data.dev_password) {
          $("td:eq(8)", row).html(
            '<h5 class="text-center"><i class="fa fa-check"></i></h5>'
          );
        }

        if (data.dev_url) {
          $("td:eq(9)", row).html(
            '<h5 class="text-center"><i class="fa fa-check"></i></h5>'
          );
        }

        if (data.production == "1")
          $("td:eq(10)", row).html(
            '<span class="badge badge-success">Active</span>'
          );
        else if (data.production == "0")
          $("td:eq(10)", row).html(
            '<span class="badge badge-warning">Not Active</span>'
          );

        if (data.active == "1")
          $("td:eq(11)", row).html(
            '<span class="badge badge-success">Active</span>'
          );
        else if (data.active == "0")
          $("td:eq(11)", row).html(
            '<span class="badge badge-warning">Not Active</span>'
          );

        if (data.auto_access == "1")
          $("td:eq(12)", row).html(
            '<span class="badge badge-success">Active</span>'
          );
        else if (data.auto_access == "0")
          $("td:eq(12)", row).html(
            '<span class="badge badge-warning">Not Active</span>'
          );
      },
      buttonClick: (evt) => {
        if (evt.role == "create") {
          self.form = {};
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Tambah Kurir";
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          self.form = evt.data;
          //jika active == 0 return false, jika active == 1 return true
          self.form.production = evt.data.production == 0 ? false : true; //operator ternary
          self.form.active = evt.data.active == 0 ? false : true; //operator ternary
          self.form.auto_access = evt.data.auto_access == 0 ? false : true; //operator ternary
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit Kurir";
          $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
          // self.form = evt.data;
          console.log(evt);
          Swal.fire({
            title: "Hapus data?",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
          }).then((result) => {
            if (result.isDenied) {
              authFetch("/master/courier/" + evt.data.id, {
                method: "DELETE",
                body: "id=" + evt.data.id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  this.table.api().ajax.reload();
                });
            }
          });
        }
      },
      initComplete: function () {
        $('.loading-overlay').removeClass('show');
      },
    });
  },
};
</script>